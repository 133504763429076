<template>
  <div>
    <ModalRightDynamic @closeModal="handleClose">
      <!-- HEADER  -->
      <template #title> {{ $t('write') }} {{ $t(title) }} </template>
      <!-- BODY CONTENT  -->
      <template #content-form>
        <v-form
          ref="modalActionEvent"
          class="mt-2 formActionEventImmo"
          v-if="actionToUse != null"
        >
          <!-- <v-row class="row">
            <v-col class="d-flex justify-end">
              <vue-ctk-date-time-picker
                :no-value-to-custom-elem="true"
                id="date-action"
                ref="pickerRef"
                :noLabel="true"
                v-model="actionToUse.scheduled_at"
                color="#45148F"
                :inline="false"
                :buttonNowTranslation="'Today'"
                inputSize="sm"
                buttonColor="#45148F"
                locale="fr"
                label=""
                :min-date="curentDate"
                class="custom-ctk-component "
              >
                <button
                  type="button"
                  class="lm-btn btn-date-component"
                  style="margin: 0;"
                >
                  <div class="icon-alarme-for-date-picker">
                    <font-awesome-icon :icon="['far', 'clock']" />
                  </div>
                </button>
              </vue-ctk-date-time-picker> </v-col
          ></v-row> -->

          <!-- TO ACTION  BlLOCK -->
          <template v-if="fields.includes('phone') || fields.includes('email')">
            <!-- TO EMAIL ACTION  BlLOCK -->
            <v-row
              class="row "
              v-for="(item, index) in actionToUse.to"
              :key="'to' + index"
            >
              <v-col
                :cols="index == 0 ? '12' : '11'"
                class="pb-0 relative-class"
              >
                <v-text-field
                  v-if="fields.includes('email')"
                  :label="$t('to')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="item.value"
                  :rules="[
                    v => !!v || $t('to') + ' ' + $t('msgOblg'),
                    rules.email
                  ]"
                  required
                  item-color="#5C2DD3"
                >
                </v-text-field>
                <div
                  class="custom-component-title"
                  v-if="fields.includes('phone')"
                >
                  {{ $t('to') }}
                </div>
                <vue-tel-input-vuetify
                  v-if="fields.includes('phone')"
                  :label="''"
                  mode="international"
                  v-model="actionToUse.to[index].value"
                  :outlined="true"
                  :rules="[
                    v => !!v || $t('to') + ' ' + $t('msgOblg'),
                    actionToUse.to[index].isValid
                  ]"
                  @validate="
                    $event => {
                      actionToUse.to[index].isValid = $event.isValid
                    }
                  "
                  wrapperClasses="customPhoneAction"
                  :persistent-placeholder="true"
                  placeholder=""
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  dense
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col clos="2" v-if="index > 0">
                <font-awesome-icon
                  class="mt-3"
                  :icon="['far', 'xmark']"
                  @click.prevent.stop="actionToUse.to.splice(index, 1)"
              /></v-col>
            </v-row>
            <!-- ADD ANOTHER  -->
            <div class="d-flex justify-end mb-4">
              <v-btn
                dark
                class="btn-immo-dashed "
                @click.prevent.stop="handelNewTo"
              >
                <span class="label-btn mr-2"> {{ $t('add_another') }}</span>
                <font-awesome-icon :icon="['far', 'plus']" />
              </v-btn></div
          ></template>
          <!-- OBJET  -->
          <v-row class="row" v-if="fields.includes('subject')">
            <v-col>
              <v-text-field
                :label="$t('subject')"
                dense
                :persistent-placeholder="true"
                outlined
                color="#5C2DD3"
                v-model="actionToUse.subject"
                :rules="[v => !!v || $t('subject') + ' ' + $t('msgOblg')]"
                item-color="#5C2DD3"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- END TO ACTION  BlLOCK -->

          <!--  EDITOR ACTION  -->
          <v-row v-if="fields.includes('editor')">
            <v-col class="relative-class"
              ><div class="custom-component-title">{{ $t(title) }}</div>
              <Editor
                v-model="actionToUse.message"
                @files="handelFiles"
              ></Editor
            ></v-col>
          </v-row>
          <!--  END EDITOR ACTION  -->

          <!--  TEXT AREA ACTION  -->
          <v-row v-if="fields.includes('textArea')">
            <v-col class="pb-0">
              <v-textarea
                dense
                :label="$t(title)"
                v-model="actionToUse.message"
                :persistent-placeholder="true"
                rows="5"
                outlined
                color="#5C2DD3"
                :rules="[v => !!v || $t(title) + ' ' + $t('msgOblg')]"
                class="textArea"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row
            v-if="fields.includes('textArea') && false"
            class="menu-message"
          >
            <v-col><FileComponent @files="handelFiles"/></v-col>
          </v-row>

          <v-row
            class="row attachment-block"
            v-for="(file, index) in actionToUse.attachments"
            :key="'attachment' + index"
          >
            <v-col :cols="'1'" class="align-self-center">
              <font-awesome-icon
                :icon="['far', 'file-arrow-up']"
                class="file-icon"
              />
            </v-col>
            <v-col :cols="'10'" class=" relative-class">
              <div class="title-attachment">{{ file.name }}</div>
              <div class="details-attachment">
                {{ (file.size / 1024).toFixed(2) }} kb
              </div>
            </v-col>
            <v-col clos="1">
              <font-awesome-icon
                class="mt-3"
                :icon="['far', 'xmark']"
                @click.prevent.stop="actionToUse.attachments.splice(index, 1)"
            /></v-col>
          </v-row>
          <template v-if="actionToUse.attachments_action">
            <v-row
              class="row attachment-block"
              v-for="(file_action, indexA) in actionToUse.attachments_action"
              :key="'attachment' + indexA"
            >
              <v-col :cols="'1'" class="align-self-center">
                <font-awesome-icon
                  :icon="['far', 'file-arrow-up']"
                  class="file-icon"
                />
              </v-col>
              <v-col :cols="'10'" class=" relative-class">
                <div class="title-attachment">{{ file_action.name }}</div>
                <div class="details-attachment" v-if="file_action.size">
                  {{ (file_action.size / 1024).toFixed(2) }} kb
                </div>
              </v-col>
              <v-col clos="1">
                <font-awesome-icon
                  class="mt-3"
                  :icon="['far', 'xmark']"
                  @click.prevent.stop="
                    actionToUse.attachments_action.splice(indexA, 1)
                  "
              /></v-col> </v-row
          ></template>
          <!--  END TEXT AREA ACTION  -->
        </v-form>
        <!-- MESSAGE SUCCES  -->
        <div class="actions-msg-btn-modal">
          <div v-if="errorMsg" class="error-msg text-center">
            <div v-if="Array.isArray(errorMsg)">
              <div
                v-for="(e, index) in errorMsg"
                :key="'get-projects-create-error-' + index"
              >
                {{ e }}
              </div>
            </div>
            <span v-else>{{ errorMsg }} </span>
          </div>
        </div>
      </template>
      <!-- FOOTER ACTION  -->
      <template #content-actions>
        <v-btn
          style="height: 30px;"
          color="#45148F"
          dark
          @click.prevent.stop="handleValiderActionEvent"
          :loading="processLoader"
        >
          <span class="label-btn-form-immo text-uppercase">
            {{ $t('send') }} {{ $t(title) }}
          </span>
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="ml-2" />
        </v-btn>
        <v-btn
          text
          class="ml-3"
          @click.prevent.stop="handleClose"
          variant="outline-secondary"
        >
          <span class="label-btn-form-immo color-black text-uppercase">
            {{ $t('btnCancel') }}</span
          >
        </v-btn></template
      >
    </ModalRightDynamic>
  </div>
</template>

<script>
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import FileComponent from './fileComponent.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'update-stage-modal',
  props: {
    actionEvent: {
      required: true
    },
    validateFunction: {
      required: true
    },
    title: { required: true },
    fields: { default: [], required: false }
  },
  data() {
    return {
      actionToUse: JSON.parse(JSON.stringify(this.actionEvent)),
      processLoader: false,
      errorMsg: null,
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (
            pattern.test(value) ||
            this.$t('invalid') + ' ' + this.$t(this.title)
          )
        }
      },
      selectedDateTimeDateStart: null,
      selectedDateTimeTimeStart: null,
      selectedDateTimeDateEnd: null,
      selectedDateTimeTimeEnd: null,
      curentDate: moment(new Date()).format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters([
      'getTypeEventLoading',
      'getTypeEvent',
      'getEventStatus',
      'allUsers'
    ])
  },
  methods: {
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1000)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    handelNewTo() {
      this.actionToUse.to.push({ value: null, isValid: false })
    },
    handleClose() {
      this.$emit('close')
      this.processLoader = false
      this.errorMsg = null
      this.actionToUse = null
      this.$refs.modalActionEvent.resetValidation()
    },
    handleOpenDateDebut(event) {
      if (!event) {
        if (!this.selectedDateTimeDateEnd && !this.selectedDateTimeTimeEnd) {
          this.selectedDateTimeDateEnd = this.selectedDateTimeDateStart
          this.selectedDateTimeTimeEnd = this.selectedDateTimeTimeStart
          this.$refs['dateEnd'].resetValidation()
        }
      }
    },
    async handleValiderActionEvent() {
      if (this.$refs.modalActionEvent.validate()) {
        this.errorMsg = null
        this.processLoader = true
        const res = await this.validateFunction(this.actionToUse)
        if (res.succes) {
          this.handleClose()
        } else {
          this.errorMsg = res.error
        }
        this.processLoader = false
      }
    },
    handleDatePicker(date, ref) {
      if (date) {
        this.$refs[ref].resetValidation()
      }
    },
    updateFunction() {},
    handelChangeEventType() {
      this.actionToUse.fields = this.actionToUse.type
        ? this.actionToUse.type.fields.map(field => ({ ...field }))
        : []
    },
    updateDateTimeStart() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateStart && this.selectedDateTimeTimeStart) {
        this.actionToUse.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
      } else if (
        this.selectedDateTimeDateStart &&
        !this.selectedDateTimeTimeStart
      ) {
        this.actionToUse.start = `${this.selectedDateTimeDateStart} 00:00`
      } else if (
        !this.selectedDateTimeDateStart &&
        this.selectedDateTimeTimeStart
      ) {
        this.selectedDateTimeDateStart = new Date().toJSON().slice(0, 10)
        this.actionToUse.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
      }
    },
    updateDateTimeEnd() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateEnd && this.selectedDateTimeTimeEnd) {
        this.actionToUse.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
      } else if (
        this.selectedDateTimeDateEnd &&
        !this.selectedDateTimeTimeEnd
      ) {
        this.actionToUse.end = `${this.selectedDateTimeDateEnd} 00:00`
      } else if (
        !this.selectedDateTimeDateEnd &&
        this.selectedDateTimeTimeEnd
      ) {
        this.selectedDateTimeDateEnd = new Date().toJSON().slice(0, 10)
        this.actionToUse.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
      }
    },
    affectEndDate() {
      this.actionToUse.start = this.actionToUse.end
    },
    handelFiles(files) {
      this.actionToUse.attachments.push(...files)
    }
  },
  mounted() {},
  components: {
    ModalRightDynamic,
    Editor: () => import('./Editeur.vue'),
    FileComponent
  },
  watch: {
    selectedDateTimeDateStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeTimeStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeDateEnd() {
      this.updateDateTimeEnd()
    },
    selectedDateTimeTimeEnd() {
      this.updateDateTimeEnd()
    }
  }
}
</script>
<style scoped lang="scss">
.formActionEventImmo {
  .btn-date-component {
    // border: 1px solid #e5e5e5;
    // width: 38px;
    // height: 36px;
    // gap: 12px;
    // border-radius: 4px;
    // background: #f6f6f6;
    padding-left: calc(100% - 40px);
    .icon-alarme-for-date-picker {
      border-radius: 4px;
      background: #f6f6f6;
      border: 1px solid #e5e5e5;
      width: 38px;
      height: 36px;
      padding: 7px;
    }
  }
  .custom-component-title {
    position: absolute;
    top: 1px;
    height: 14px;
    left: 24px;
    background: #fff;
    z-index: 8;
    padding: 0px 2px;
  }
  .relative-class {
    position: relative;
  }
  .textArea {
    font-size: 13px !important;
  }
  .attachment-block {
    .file-icon {
      color: #45148f;
    }
    .details-attachment {
      font-size: 12px;
      font-weight: 400;
      line-height: px;
      letter-spacing: 0.17000000178813934px;
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
    }
    .title-attachment {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .menu-message {
    border: 1px solid #e5e5e5;
    margin: 4px 0px 0px 0px !important;
    border-radius: 4px;
    display: flex;
    gap: 10px;
    background: #f6f6f6;
  }
}
</style>
<style lang="scss">
#date-action {
  .field #CustomInput {
    padding-top: 0px;
  }
  // width: 40px;
  // margin: 0px;
}
.customPhoneAction {
  fieldset,
  .v-input--is-focused fieldset {
    border: 1px solid #0000003b;
  }
  .v-input fieldset {
    border-left: none;
    border-radius: 0px 4px 4px 0px;
  }
  .country-code fieldset {
    border-right: none;
    border-left: 1px solid #0000003b;
    border-radius: 4px 0px 0px 4px;
  }
}
</style>
